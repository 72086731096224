<template>
  <div class="v-content" >
   
      <h1 class="titulo_principal"> CARRERAS DE FORMACIÓN </h1>
      
      <b-container class="carreras">
        <b-row class="text-center">
          <b-col cols="12">
            <h2> MECÁNICA AUTOMOTRIZ</h2>
            <b-img :src="require('@/assets/carreras/mecanica.jpg')" fluid alt="Mecánica Automotriz"></b-img>
          </b-col>          
        </b-row>
        <b-row class="text-center">
          <b-col cols="12">
            <h2> EDUCACIÓN PARVULARIA</h2>
            <b-img :src="require('@/assets/carreras/parvulario.jpg')" fluid alt="Educación Parvularia"></b-img>
          </b-col>          
        </b-row>
        <b-row class="text-center">
          <b-col cols="12">
            <h2> GASTRONOMÍA</h2>
            <b-img :src="require('@/assets/carreras/gastronomia.jpg')" fluid alt="Gastronomía"></b-img>
          </b-col>          
        </b-row>
        <b-row class="text-center">
          <b-col cols="12">
            <h2> ENFERMERÍA</h2>
            <b-img :src="require('@/assets/carreras/enfermeria.jpg')" fluid alt="Enfermería"></b-img>
          </b-col>          
        </b-row>
        <b-row class="text-center">
          <b-col cols="12">
            <h2> SECRETARIADO ADMINISTRATIVO</h2>
            <b-img :src="require('@/assets/carreras/secretariado.jpg')" fluid alt="Secretariado Administrativo"></b-img>
          </b-col>          
        </b-row>
        <b-row class="text-center">
          <b-col cols="12">
            <h2> PERITO EN BANCA</h2>
            <b-img :src="require('@/assets/carreras/perito.jpg')" fluid alt="Perito en Banca"></b-img>
          </b-col>          
        </b-row>

      </b-container>
  </div>
</template>

<script>
export default {
  name: 'carreras',
  components: {
    
  }
}
</script>
<style>

.titulo_principal {
    padding: 3rem 1rem;
    text-align: center;
    color: #2956b2;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.5rem;

    font-family: Montserrat,Helvetica,sans-serif;
    -webkit-font-smoothing: antialiased;
}

h2{
    border-radius: 8px;
    box-shadow: 0 12px 20px -10px rgb(255 90 25 / 28%), 0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(255 90 25 / 20%);
    background: #0d6efd;
    background: linear-gradient(0deg,#0d6efd,#5689cf); 
    width: 95%;
    text-align: center;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 1rem 0.5rem; 
    font-size: 1rem;
    font-weight: 400;
    
    font-weight: 500;
    color: #e5eee9;
} 
</style>